import React from 'react';
import ComingSoon from '../shared/ComingSoon/ComingSoon';

export const Contact = () => {
	return (
		<div>
			<ComingSoon />
		</div>
	);
};
