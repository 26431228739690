import styles from './Sidebar.module.scss';
import { Navigation } from '../Navigation/Navigation';

type SidebarProps = {
	pageIndex: number;
	onPageChange(pageIndex: number): void;
};

function Sidebar({ pageIndex, onPageChange }: SidebarProps) {
	return (
		<aside className={styles.sidebar}>
			<span className={styles.logo}>
				ab
				<span>.</span>
			</span>

			<Navigation onPageChange={onPageChange} pageIndex={pageIndex} />
		</aside>
	);
}

export default Sidebar;
