import { useState } from 'react';
import styles from './Skills.module.scss';
import PopOver from '../shared/PopOver.tsx/PopOver';

type Skill = {
	name: string;
	logoUrl: string;
	level: number;
	category: string;
	active?: boolean;
	width?: string;
};
type SkillGroupProps = {
	title: string;
	skills: Skill[];
	onGroupSelected: () => void;
};

export const SkillGroup = ({
	title,
	skills,
	onGroupSelected,
}: SkillGroupProps) => {
	const [isActive, setIsActive] = useState(false);

	function setGroupActive() {
		setIsActive(!isActive);
		onGroupSelected();
	}
	return (
		<div
			className={`${styles.skillGroup} ${isActive ? styles.active : ''}`}
			onClick={() => setGroupActive()}
		>
			<span className={styles.skillGroupTitle}>
				<span className={styles.skillGroupTitleText}>{title}</span>
			</span>
			<div className={styles.skillLogosContainer}>
				{skills.map((skill: Skill) => (
					<PopOver key={skill.name} text={skill.name}>
						<img
							className={styles.skillLogo}
							src={skill.logoUrl}
							alt={skill.name}
							style={{ width: skill.width }}
							title={skill.name}
						/>
					</PopOver>
				))}
			</div>
		</div>
	);
};
