import { MutableRefObject, useRef, useState } from 'react';
import './App.scss';
import Sidebar from './Sidebar/Sidebar';
import { PageOutlet } from './PageOutlet/PageOutlet';

function App() {
	const [pageIndex, setPageIndex] = useState(0);
	const scrolling: MutableRefObject<boolean> = useRef<boolean>(false);
	const animating: MutableRefObject<boolean> = useRef<boolean>(false);
	const touchStart: MutableRefObject<number> = useRef<number>(0);
	const touchEnd: MutableRefObject<number> = useRef<number>(0);
	const skillsScrollContainerRef: MutableRefObject<HTMLDivElement | null> =
		useRef<HTMLDivElement>(null);

	function onPageChange(selectedPageIndex: number) {
		setPageIndex(selectedPageIndex);
	}

	function handleScroll(event: React.WheelEvent<HTMLDivElement>) {
		const wheelEvent = event as unknown as WheelEvent;
		if (Math.abs(wheelEvent.deltaY) <= 70 || animating.current) {
			return;
		}
		// console.log('deltaY', wheelEvent.deltaY);
		scrolling.current = true;

		if (wheelEvent.deltaY > 0) {
			if (pageIndex < 3) {
				setPageIndex(pageIndex + 1);
				animating.current = true;
			}
		} else {
			if (pageIndex > 0) {
				setPageIndex(pageIndex - 1);
				animating.current = true;
			}
		}
	}

	function handleTouchSwipe(e: React.TouchEvent) {
		touchEnd.current = e.changedTouches[0].clientY;

		if (
			pageIndex === 1 &&
			skillsScrollContainerRef.current!.scrollTop +
				skillsScrollContainerRef.current!.clientHeight !==
				skillsScrollContainerRef.current!.scrollHeight &&
			skillsScrollContainerRef.current!.scrollTop !== 0
		) {
			return;
		}

		if (touchStart.current > touchEnd.current + 5 && pageIndex < 4) {
			console.log('next page');
			setPageIndex((currentPage: number) => currentPage + 1);
		} else if (touchStart.current < touchEnd.current - 5 && pageIndex > 0) {
			console.log('prev page');
			setPageIndex((currentPage: number) => currentPage - 1);
		}
	}

	function onPageAnimationEnd() {
		animating.current = false;
	}

	// function onPageRender(
	// 	skillsContainer: MutableRefObject<HTMLDivElement | null>
	// ) {
	// 	skillsScrollContainerRef = skillsContainer;
	// }

	return (
		<div
			onWheelCapture={(e) => handleScroll(e)}
			onTouchStart={(e: React.TouchEvent) => {
				touchStart.current = e.touches[0].clientY;
			}}
			onTouchEnd={(e: React.TouchEvent) => handleTouchSwipe(e)}
		>
			<Sidebar onPageChange={onPageChange} pageIndex={pageIndex} />

			<PageOutlet
				pageIndex={pageIndex}
				onPageAnimationEnd={onPageAnimationEnd}
				forwardedRef={skillsScrollContainerRef}
			/>
		</div>
	);
}

export default App;
