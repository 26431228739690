import Skill from './Skill';
import angularLogo from './../assets/images/skills/Angular logo.svg';
import dotNetLogo from './../assets/images/skills/Dot logo.svg';
import sqlServerLogo from './../assets/images/skills/Microsoft logo.svg';
import oracleLogo from './../assets/images/skills/Oracle logo.svg';
import awsLogo from './../assets/images/skills/aws logo.svg';
import azureLogo from './../assets/images/skills/azure logo.svg';
import reactLogo from './../assets/images/skills/file_type_reactjs logo.svg';
import pythonLogo from './../assets/images/skills/python logo.svg';
import springBootLogo from './../assets/images/skills/springboot logo.svg';
import typescriptLogo from './../assets/images/skills/typescript logo.svg';
import javascriptLogo from './../assets/images/skills/javascript logo.svg';
import jqueryLogo from './../assets/images/skills/Jquery logo.svg';
import htmlLogo from './../assets/images/skills/html5-plain-wordmark logo.svg';
import cssLogo from './../assets/images/skills/css3-plain-wordmark logo.svg';
import lodashLogo from './../assets/images/skills/lodash logo.svg';
import javaLogo from './../assets/images/skills/Java logo.svg';
import raspberryPiLogo from './../assets/images/skills/rasberry-pi logo.svg';
import cSharpLogo from './../assets/images/skills/c-sharp logo.svg';
import nodeLogo from './../assets/images/skills/NodeJS logo.svg';
import SQSLogo from './../assets/images/skills/SQS logo.svg';
import SNSLogo from './../assets/images/skills/SNS logo.svg';
import styles from './Skills.module.scss';
import helperStyles from './../AppHelpers.module.scss';
import { SkillGroup } from './SkillGroup';
import { MutableRefObject, useEffect, useRef, useState } from 'react';

const skills: Skill[] = [
	{
		name: 'HTML',
		logoUrl: htmlLogo,
		level: 7,
		category: 'Front End',
	},
	{
		name: 'CSS',
		logoUrl: cssLogo,
		level: 7,
		category: 'Front End',
	},
	{
		name: 'Type Script',
		logoUrl: typescriptLogo,
		level: 7,
		category: 'Front End',
	},
	{
		name: 'Java Script',
		logoUrl: javascriptLogo,
		level: 7,
		category: 'Front End',
	},
	{ name: 'Angular', logoUrl: angularLogo, level: 8, category: 'Front End' },
	{ name: 'React', logoUrl: reactLogo, level: 6, category: 'Front End' },
	{
		name: 'Jquery',
		logoUrl: jqueryLogo,
		level: 7,
		category: 'Front End',
		width: '130px',
	},
	{
		name: 'Lodash',
		logoUrl: lodashLogo,
		level: 7,
		category: 'Front End',
		width: '50px',
	},
	{
		name: 'Java',
		logoUrl: javaLogo,
		level: 7,
		category: 'Back End',
		width: '55px',
	},
	{
		name: 'C#',
		logoUrl: cSharpLogo,
		level: 7,
		category: 'Back End',
	},
	{
		name: 'Spring Boot',
		logoUrl: springBootLogo,
		level: 7,
		category: 'Back End',
	},
	{
		name: 'Dot Net',
		logoUrl: dotNetLogo,
		level: 6,
		category: 'Back End',
		width: '80px',
	},
	{ name: 'Python', logoUrl: pythonLogo, level: 4, category: 'Back End' },
	{
		name: 'RaspberryPi',
		logoUrl: raspberryPiLogo,
		level: 4,
		category: 'Back End',
		width: '50px',
	},
	{
		name: 'Node JS',
		logoUrl: nodeLogo,
		level: 4,
		category: 'Back End',
		width: '200px',
	},
	{
		name: 'Oracle',
		logoUrl: oracleLogo,
		level: 6,
		category: 'Database',
		width: '130px',
	},
	{
		name: 'SQL Server',
		logoUrl: sqlServerLogo,
		level: 5,
		category: 'Database',
		width: '130px',
	},
	{
		name: 'Amazon Web Services',
		logoUrl: awsLogo,
		level: 4,
		category: 'Cloud',
	},
	{ name: 'Amazon SQS', logoUrl: SQSLogo, level: 4, category: 'Cloud' },
	{ name: 'Amazon SNS', logoUrl: SNSLogo, level: 4, category: 'Cloud' },
	{
		name: 'Microsoft Azure',
		logoUrl: azureLogo,
		level: 4,
		category: 'Cloud',
		width: '120px',
	},
];

type SkillsProps = {
	forwardedRef: MutableRefObject<HTMLDivElement | null>;
};

export const Skills = ({ forwardedRef }: SkillsProps) => {
	const [isActive, setIsActive] = useState(false);
	// const skillsScrollContainerRef: MutableRefObject<HTMLDivElement | null> =
	// 	useRef<HTMLDivElement>(null);

	function onGroupSelected() {
		setIsActive(!isActive);
	}

	// useEffect(() => {
	// 	onPageRender(skillsScrollContainerRef);
	// }, []);

	return (
		<section className={styles.skills}>
			<div className={styles.skillGroupContainer} ref={forwardedRef}>
				<div
					className={`${styles.skillGroupGrid} ${
						isActive ? styles.active : ''
					}`}
				>
					<SkillGroup
						title="Front End Technologies"
						skills={skills.filter((s) => s.category === 'Front End')}
						onGroupSelected={onGroupSelected}
					/>
					<SkillGroup
						title="Back End Technologies"
						skills={skills.filter((s) => s.category === 'Back End')}
						onGroupSelected={onGroupSelected}
					/>
					<SkillGroup
						title="Database Technologies"
						skills={skills.filter((s) => s.category === 'Database')}
						onGroupSelected={onGroupSelected}
					/>
					<SkillGroup
						title="Cloud Technologies"
						skills={skills.filter((s) => s.category === 'Cloud')}
						onGroupSelected={onGroupSelected}
					/>
					{/* {skills.map((skill) => (
					<div className={styles.skillRow} key={skill.name}>
						<img
							className={styles.skillLogo}
							src={skill.logoUrl}
							alt={skill.name}
						/>
						<span className={styles.skillBar} data-aria-level={skill.level}>
							<span
								className={styles.skillValue}
								style={{ width: `${skill.level * 10}%` }}
							></span>
						</span>
					</div>
				))} */}
				</div>
			</div>
		</section>
	);
};
