import { useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import styles from './PopOver.module.scss';

type PopOverProps = {
	text: string;
	children: React.ReactNode;
};
function PopOver({ text, children }: PopOverProps) {
	const [isHovering, setIsHovering] = useState(false);
	const container = useRef<HTMLDivElement>(null);
	const popOver = useRef<HTMLDivElement>(null);

	const containerPosition = container.current?.getBoundingClientRect() || {
		top: 0,
		left: 0,
		width: 0,
	};
	const popOverposition = popOver.current?.getBoundingClientRect() || {
		top: 0,
		left: 0,
		width: 0,
	};
	return (
		<div
			ref={container}
			onMouseEnter={() => setIsHovering(true)}
			onMouseLeave={() => setIsHovering(false)}
		>
			{createPortal(
				<div
					ref={popOver}
					className={styles.popover}
					style={{
						top:
							containerPosition.top - (popOver.current?.clientHeight || 0) - 20,
						left: containerPosition.left - containerPosition.width / 2,
						width: containerPosition.width * 2,
						visibility: isHovering ? 'visible' : 'hidden',
					}}
				>
					<span>{text}</span>
				</div>,
				document.body
			)}

			{children}
		</div>
	);
}

export default PopOver;
