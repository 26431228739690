import { useEffect, useState } from 'react';
import styles from './Navigation.module.scss';
import NavItem from './NavItem';

const navItemsData: NavItem[] = [
	{ text: 'ABOUT ME', link: '#about-me', active: true },
	// { text: 'EXPERIENCE', link: '#experience' },
	{ text: 'SKILLS', link: '#skills' },
	{ text: 'PROJECTS', link: '#projects' },
	{ text: 'CONTACT', link: '#contact' },
];

type NavigationProps = {
	pageIndex: number;
	onPageChange(pageIndex: number): void;
};
export const Navigation = ({ pageIndex, onPageChange }: NavigationProps) => {
	const [navItems, setNavItems] = useState(navItemsData);

	const setActive = (navItem: NavItem) => {
		const newNavItems = navItems.map((item) => {
			if (item.text === navItem.text) {
				item.active = true;
			} else {
				item.active = false;
			}
			return item;
		});
		setNavItems(newNavItems);

		onPageChange(navItems.indexOf(navItem));
	};

	useEffect(() => {
		setActive(navItems[pageIndex]);
	}, [pageIndex]);
	return (
		<nav className={styles.navbar}>
			<span className={styles.line}></span>
			<ul className={styles.list}>
				{navItems.map((item) => (
					<li
						className={`${styles.item} ${item.active ? styles.active : ''}`}
						key={item.text}
						onClick={() => setActive(item)}
					>
						<span className={styles.itemText}>{item.text}</span>
					</li>
				))}
			</ul>
		</nav>
	);
};
