import styles from './AboutMe.module.scss';
import helperStyles from './../AppHelpers.module.scss';
import { Avatar } from './Avatar';
import xitFullLogo from './../assets/images/xit-full-logo.png';

export const AboutMe = () => {
	return (
		<section className={styles.aboutMe}>
			<div className={styles.intro}>
				<div className={styles.avatar}>
					<Avatar />
				</div>
				<div className={styles.introText}>
					<h1 className={styles.largeHeader}>
						<span className={helperStyles.backgroundTextColor}>I&apos;m</span>{' '}
						Abdul Aleem{' '}
					</h1>
					<h1 className={styles.largeHeader}>Senior Full Stack</h1>
					<h1 className={styles.largeHeader}>
						Software Engineer
						<span className={helperStyles.accentTextColor}>.</span>
					</h1>
					<p>
						I have 8+ years of Software Engineering experience, currently
						working @ <img src={xitFullLogo} className={styles.xitLogo} /> I
						have a passion to convert an idea into a production ready,
						performant and scalable SAAS solutions.
					</p>
				</div>
			</div>
		</section>
	);
};
