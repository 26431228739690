import styles from './PageOutlet.module.scss';
import { AboutMe } from './../AboutMe/AboutMe';
import { Skills } from './../Skills/Skills';
import { Contact } from './../Contact/Contact';
import { Projects } from '../Projects/Projects';
import { MutableRefObject, useRef } from 'react';

type PageOutletProps = {
	pageIndex: number;
	onPageAnimationEnd: () => void;
	forwardedRef: MutableRefObject<HTMLDivElement | null>;
};

export const PageOutlet = ({
	pageIndex,
	onPageAnimationEnd,
	forwardedRef,
}: PageOutletProps) => {
	// function onSkillPageRender(
	// 	skillContainerRef: MutableRefObject<HTMLDivElement | null>
	// ) {
	// 	onPageRender(skillContainerRef);
	// }
	return (
		<div className={`${styles.pageOutlet} page-${pageIndex}`}>
			<div
				className={styles.scroller}
				style={{ transform: `translateY(-${pageIndex * 100}vh)` }}
				onTransitionEnd={onPageAnimationEnd}
			>
				<AboutMe />
				<Skills forwardedRef={forwardedRef} />
				<Projects />
				<Contact />
			</div>
		</div>
	);
};
